<template>
  <li
    class="dropzone"
    @dragover="handleOnDragOver"
    @drop="onDrop"
    @dragenter="onDragEnterHandler"
    @dragleave="onDragLeaveHandler"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: "DroppableItem",
  props: {
    // triggered when dragging an element over it
    onDragOver: {
      type: Function,
      required: false,
    },
    // triggered when dragging an element out of it
    onDragLeave: {
      type: Function,
      required: false,
    },
    // triggered when dropping an element into it
    onDrop: {
      type: Function,
      required: false,
    },
  },
  methods: {
    /**
     * Triggered when dragging an element over it;
     * @param event
     */
    handleOnDragOver(event) {
      event.preventDefault();
      this.onDragOver && this.onDragOver(event);
    },
    onDragEnterHandler(event) {
      if (event.target.className.includes("placeholder"))
        event.target.style.background = "#546E7A";
    },
    onDragLeaveHandler(event) {
      if (event.target.className.includes("placeholder"))
        event.target.style.background = "#cfd8dc";
    },
  },
};
</script>

<style scoped>
.dragged-over {
  background-color: #78909c;
}
</style>
