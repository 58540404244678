
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "TextCarousel",
})
export default class TextCarousel extends Vue {
  @Prop(Array) items!: { id: string; content: string }[];
  @Prop({ type: Boolean, default: false }) controlEnabled: boolean | undefined;
  @Prop({ type: Number, default: 20000 }) timeout: number | undefined;
  currentIndex = 0;
  currentDegree = 0;

  itemCss = "";
  carouselCss = "";

  triggerId: number | undefined;

  created(): void {
    this.triggerId = window.setInterval(
      () => {
        if (this.currentIndex < this.items.length - 1) {
          this.moveRight();
        } else {
          this.currentIndex = 0;
        }
      },
      this.timeout ? this.timeout : 2000
    );
  }

  beforeDestroy(): void {
    window.clearInterval(this.triggerId);
  }

  moveLeft(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
    this.rotate("left");
  }

  moveRight(): void {
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
    }
    this.rotate("right");
  }

  rotate(direction: string): void {
    if (direction === "right") {
      this.currentDegree -= 120;
    }
    if (direction === "left") {
      this.currentDegree += 120;
    }
    this.carouselCss =
      "-webkit-transform: rotateY(" +
      this.currentDegree +
      "deg)" +
      "-moz-transform: rotateY(" +
      this.currentDegree +
      "deg)" +
      "-o-transform: rotateY(" +
      this.currentDegree +
      "deg)" +
      "transform: rotateY(" +
      this.currentDegree +
      "deg)";

    this.itemCss =
      "-webkit-transform: rotateY(" +
      -this.currentDegree +
      "deg)" +
      "-moz-transform: rotateY(" +
      -this.currentDegree +
      "deg)" +
      "-o-transform: rotateY(" +
      -this.currentDegree +
      "deg)" +
      "transform: rotateY(" +
      -this.currentDegree +
      "deg)";
  }

  goToIndex(index: number) {
    console.log("goToIndex");
    this.currentIndex = index;
  }
}
