var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center align-center flex-column"},[_c('div',{staticClass:"container d-flex flex-row justify-space-between align-center mt-16"},[(_vm.controlEnabled)?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.currentIndex === 0,"icon":"","dark":"","small":"","color":"black"},on:{"click":_vm.moveLeft}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-left-bold ")])],1):_vm._e(),_c('div',{staticClass:"carousel",style:(_vm.carouselCss)},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:index == _vm.currentIndex - 1
            ? `left-card small-card item`
            : index == _vm.currentIndex + 1
            ? `right-card small-card item`
            : _vm.currentIndex == index
            ? `big-card item`
            : `hidden`},[_c('p',[_vm._v(_vm._s(item.content))])])}),0),(_vm.controlEnabled)?_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":_vm.currentIndex === _vm.items.length - 1,"icon":"","dark":"","small":"","color":"black"},on:{"click":_vm.moveRight}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-arrow-right-bold ")])],1):_vm._e()],1),_c('div',{staticClass:"bullet-container"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"bullet",class:{ active: _vm.currentIndex === index },on:{"click":function($event){return _vm.goToIndex(index)}}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }