
import { Prop, Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { ITip } from "@/schemas/ITip";
import { mapState } from "vuex";

@Component({
  name: "Tip",
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class Tip extends Vue {
  @Prop({ required: true, type: Object })
  tip!: ITip;
  @Prop({ required: true, type: Number })
  sessionTime!: number;
  @Prop({ required: true, type: Number | String })
  startedAt!: number;

  displayAt: Date = new Date(Date.now());
  hideAt: Date = new Date(Date.now());
  highlightAt: Date = new Date(Date.now());
  highlightEntAt: Date = new Date(Date.now());

  triggerId = NaN;
  highlight = false;
  display = false;

  @Watch("highlight")
  highlightChanged(val: boolean): void {
    this.$emit("highlightChanged", {
      tip_id: this.tip.id,
      is_highlight: val,
    });
  }

  get rankedItems(): number {
    return (
      Object.keys(
        Object.values(this.$store.state.session.rankedItems).filter(
          (o) => !!Object.values(o).length
        )
      ).length || 0
    );
  }

  created(): void {
    if (this.tip.display_at) {
      let start = new Date(this.startedAt || Date.now());
      let timeToAdd = (this.tip.display_at * this.sessionTime) / 100;
      let minutes = Math.floor(start.getMinutes() + timeToAdd);
      let seconds =
        parseInt(timeToAdd.toString().split(".")[1]) + start.getSeconds();
      start.setMinutes(minutes || 0, seconds || 0);
      this.displayAt = start;
    }
    if (this.tip.remove_at) {
      let start = new Date(this.startedAt || Date.now());
      let timeToAdd = (this.tip.remove_at * this.sessionTime) / 100;
      let minutes = Math.floor(start.getMinutes() + timeToAdd);
      let seconds =
        parseInt(timeToAdd.toString().split(".")[1]) + start.getSeconds();
      start.setMinutes(minutes || 0, seconds || 0);
      this.hideAt = start;
    }
    if (this.tip.highlight_end_at) {
      let start = new Date(this.startedAt || Date.now());
      let timeToAdd = (this.tip.highlight_end_at * this.sessionTime) / 100;
      let minutes = Math.floor(start.getMinutes() + timeToAdd);
      let seconds =
        parseInt(timeToAdd.toString().split(".")[1]) + start.getSeconds();
      start.setMinutes(minutes || 0, seconds || 0);
      this.highlightEntAt = start;
    }
    if (this.tip.highlight_begin_at) {
      let start = new Date(this.startedAt || Date.now());
      let timeToAdd = (this.tip.highlight_begin_at * this.sessionTime) / 100;
      let minutes = Math.floor(start.getMinutes() + timeToAdd);
      let seconds =
        parseInt(timeToAdd.toString().split(".")[1]) + start.getSeconds();
      start.setMinutes(minutes || 0, seconds || 0);
      this.highlightAt = start;
    }

    this.triggerId = window.setInterval(() => {
      this.refreshHighlight();
    }, 1000);
  }

  beforeDestroy(): void {
    clearInterval(this.triggerId);
  }

  refreshHighlight(): void {
    let now = new Date(Date.now());

    this.highlight =
      (now.getTime() > this.highlightAt.getTime() &&
        now.getTime() < this.highlightEntAt.getTime()) ||
      ((this.tip.completion_highlight_at
        ? this.rankedItems >= this.tip.completion_highlight_at
        : false) &&
        (this.tip.completion_highlight_end_at
          ? this.rankedItems < this.tip.completion_highlight_end_at
          : true));

    this.display =
      (now.getTime() > this.displayAt.getTime() &&
        now.getTime() < this.hideAt.getTime()) ||
      ((this.tip.completion_display_at
        ? this.rankedItems >= this.tip.completion_display_at
        : false) &&
        (this.tip.completion_remove_at
          ? this.rankedItems < this.tip.completion_remove_at
          : true));
  }
}
