import { IEmptyItem, IItem } from "@/schemas/IItem";
import { IMove } from "@/schemas/IMove";

const moveHandlers = {
  moveItem(
    move: IMove,
    array: Array<IItem | IEmptyItem>,
    item: IItem
  ): Array<IItem | IEmptyItem> {
    if (move.old_index < array.length && move.new_index < array.length) {
      if (array.some((item) => (item.id ? item.id === move.item_id : false))) {
        if (
          array[move.old_index].id &&
          array[move.old_index].id === move.item_id
        ) {
          array.splice(move.old_index, 1);
          if (
            array.some((item) => (item.id ? item.id === move.item_id : false))
          ) {
            array.splice(move.old_index, 0, {});
            return array;
          }
          array.splice(move.new_index, 0, item);
          return array;
        }
      }
    }
    return array;
  },
  removeItem(move: IMove, array: IItem[]): IItem[] {
    return array.length
      ? array.filter((item) => item.id !== move.item_id)
      : array;
  },
  addItem(move: IMove, array: IItem[], item: IItem): IItem[] {
    if (Object.keys(array[move.new_index]).length === 0) {
      array[move.new_index] = item;
    } else if (array.length) {
      if (move.new_index >= array.length) {
        array.push(item);
      } else {
        array.push(array[array.length - 1]);
        for (let i = array.length - 1; i > move.new_index; i--) {
          array[i] = array[i - 1];
        }
        array[move.new_index] = item;
      }
    } else {
      array.push(item);
    }
    return array;
  },
};
export default moveHandlers;
